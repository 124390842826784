(function ($) {
    $(function () {
        const toggleShippingAddressDisplay = function ($check) {
            const $shippingAddress = $('div.shipping_address');
            $shippingAddress.toggleClass('d-none', !$check.prop('checked'));
        };

        if ($('body.checkout').length) {
            toggleShippingAddressDisplay();

            $('#ship-to-different-address input').on('change', function () {
                toggleShippingAddressDisplay($(this));
            });
        }
    });
})(window.jQuery);
